import { GameTypeMap } from '../generated/lobby_pb';
import { RouteConstant } from './route.constants';

export const GameTypeNames: { [P in keyof Omit<GameTypeMap, 'UNSPECIFIED'>]: string } = {
  MAHJONG: 'Mahjong Riichi',
  SEVEN_CLUBS: 'Seven clubs',
  HOKM: 'Hokm',
};

export const GameTypeRoutes: { [P in keyof Omit<GameTypeMap, 'UNSPECIFIED'>]: RouteConstant } = {
  MAHJONG: RouteConstant.MAHJONG,
  SEVEN_CLUBS: RouteConstant.SEVEN_CLUBS,
  HOKM: RouteConstant.HOKM,
};
