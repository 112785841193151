import React from 'react';
import cx from 'classnames';

import styles from './settings.module.scss';
import { Checkbox } from '../checkbox/checkbox.component';
import { useBooleanState } from '../../hooks/boolean-state.hook';

type Props = {
  classicCardFaces: boolean;
  setClassicCardFaces: (value: boolean) => void;
};

export const Settings = (props: Props) => {
  const { state: open, toggle: toggleOpen } = useBooleanState(false);

  return (
    <div className={cx(styles.container, { [styles.isOpen]: open })}>
      <button className={styles.settingsBtn} onClick={toggleOpen}>
        <i className={`fa-solid fa-${open ? 'xmark' : 'gear'}`} />
      </button>

      {open && (
        <div className={styles.settingsCard}>
          <Checkbox
            name="classic"
            value={props.classicCardFaces}
            label="Classic card faces"
            onChange={props.setClassicCardFaces}
          />
        </div>
      )}
    </div>
  );
};
