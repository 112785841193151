import { grpc } from '@improbable-eng/grpc-web';
// import { Request } from '@improbable-eng/grpc-web/dist/typings/invoke';
import { Subject } from 'rxjs';

import { Lobby as LobbyService } from '../generated/lobby_pb_service';
import {
  CreateRoomRequest,
  CreateRoomResponse,
  GameTypeMap,
  JoinRoomRequest,
  JoinRoomResponse,
  StartGameRequest,
} from '../generated/lobby_pb';
import { API_BASE_URL } from '../env';

export const createRoom = (gameType: GameTypeMap[keyof GameTypeMap]) => {
  return new Promise<string>((resolve, reject) => {
    const request = new CreateRoomRequest();
    request.setGametype(gameType);

    grpc.unary(LobbyService.createRoom, {
      host: API_BASE_URL,
      request,
      onEnd: (res) => {
        if (res.status === grpc.Code.OK && res.message) {
          const message = res.message as CreateRoomResponse;

          return resolve(message.getRoomcode());
        }

        reject('Could not create room');
      },
    });
  });
};

type MahjongRoom = {
  players: string[];
  observers: string[];
  playerId: string;
  gameType: GameTypeMap[keyof GameTypeMap];
  ready: boolean;
  gameId: string;
};

// let roomRequest: Request | undefined = undefined;

export const joinRoom = (roomCode: string, playerName: string, playerId?: string, asObserver: boolean = false) => {
  const subject = new Subject<MahjongRoom>();
  const request = new JoinRoomRequest();
  request.setRoomcode(roomCode);
  request.setPlayername(playerName);
  request.setObserver(asObserver);

  if (playerId) {
    request.setPlayerid(playerId);
  }

  const r = grpc.invoke(LobbyService.joinRoom, {
    host: API_BASE_URL,
    request,
    onMessage: (message: JoinRoomResponse) => {
      const players = message.getPlayersList();
      const observers = message.getObserversList();
      const playerId = message.getPlayerid();
      const gameType = message.getGametype();
      const ready = message.getReady();
      const gameId = message.getGameid();

      subject.next({
        players,
        observers,
        playerId,
        gameType,
        ready,
        gameId,
      });

      if (gameId) {
        r.close();
      }
    },
    onEnd: (res) => {
      subject.complete();
    },
  });

  return subject;
};

export const startGame = (roomCode: string, playerId: string) => {
  const request = new StartGameRequest();
  request.setRoomcode(roomCode);
  request.setPlayerid(playerId);

  grpc.unary(LobbyService.startGame, {
    host: API_BASE_URL,
    request,
    onEnd: () => {},
  });
};
