// package: lobby
// file: lobby.proto

var lobby_pb = require("./lobby_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var Lobby = (function () {
  function Lobby() {}
  Lobby.serviceName = "lobby.Lobby";
  return Lobby;
}());

Lobby.createRoom = {
  methodName: "createRoom",
  service: Lobby,
  requestStream: false,
  responseStream: false,
  requestType: lobby_pb.CreateRoomRequest,
  responseType: lobby_pb.CreateRoomResponse
};

Lobby.joinRoom = {
  methodName: "joinRoom",
  service: Lobby,
  requestStream: false,
  responseStream: true,
  requestType: lobby_pb.JoinRoomRequest,
  responseType: lobby_pb.JoinRoomResponse
};

Lobby.startGame = {
  methodName: "startGame",
  service: Lobby,
  requestStream: false,
  responseStream: false,
  requestType: lobby_pb.StartGameRequest,
  responseType: lobby_pb.StartGameResponse
};

exports.Lobby = Lobby;

function LobbyClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

LobbyClient.prototype.createRoom = function createRoom(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Lobby.createRoom, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

LobbyClient.prototype.joinRoom = function joinRoom(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(Lobby.joinRoom, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

LobbyClient.prototype.startGame = function startGame(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Lobby.startGame, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.LobbyClient = LobbyClient;

