function getVariable(name: string, optional?: false): string;
function getVariable(name: string, optional: true): string | undefined;
function getVariable(name: string, optional = false) {
  const result = process.env[name];

  if (optional) {
    return result;
  }

  if (result === null || result === undefined) {
    throw Error(`Could not find required env variable '${name}'`);
  }

  return result;
}

export const API_BASE_URL = getVariable('REACT_APP_API_BASE_URL');
