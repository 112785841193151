import React, { useState } from 'react';

import { RoundIconBtn } from '../round-icon-btn/round-icon-btn.component';

import styles from './room.module.scss';

type Props = {
  roomCode: string;
  players: string[];
  observers: string[];
  startGame?: () => void;
};

export const Room = (props: Props) => {
  const [showObservers, setShowObservers] = useState(false);
  const players = showObservers ? props.observers : props.players;

  return (
    <div className={styles.container}>
      <div className={styles.code}>{props.roomCode}</div>

      <div className={styles.observersCount} onClick={() => setShowObservers(!showObservers)}>
        {showObservers ? (
          <span className={styles.observersIcon}>
            <i className="fas fa-eye" />
          </span>
        ) : (
          props.observers.length
        )}
      </div>

      <div className={styles.players}>
        {players.map((player) => (
          <div key={player} className={styles.player}>
            {player}
          </div>
        ))}
      </div>

      {props.startGame && (
        <div className={styles.playContainer}>
          <RoundIconBtn name="play" onClick={props.startGame} />
        </div>
      )}
    </div>
  );
};
