import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { HashRouter as Router, Route } from 'react-router-dom';

import { RouteConstant } from '../../constants/route.constants';
import { Home } from '../home/home.container';
import { Room } from '../room/room.container';

const Hokm = React.lazy(() => import('../hokm/hokm.container'));
const MahjongTable = React.lazy(() => import('../mahjong/mahjong-table/mahjong-table.container'));
const SevenClubs = React.lazy(() => import('../seven-clubs/seven-clubs.container'));

export const App = () => {
  return (
    <DndProvider backend={HTML5Backend}>
      <Router>
        <Route exact path={RouteConstant.HOME}>
          <Home />
        </Route>

        <Route path={`${RouteConstant.ROOM}/:roomCode`}>
          <Room />
        </Route>

        <Route path={`${RouteConstant.HOKM}/:gameId`}>
          <React.Suspense fallback={null}>
            <Hokm />
          </React.Suspense>
        </Route>

        <Route path={`${RouteConstant.MAHJONG}/:gameId`}>
          <React.Suspense fallback={null}>
            <MahjongTable />
          </React.Suspense>
        </Route>

        <Route path={`${RouteConstant.SEVEN_CLUBS}/:gameId`}>
          <React.Suspense fallback={null}>
            <SevenClubs />
          </React.Suspense>
        </Route>
      </Router>
    </DndProvider>
  );
};
