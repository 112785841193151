import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { joinRoom, startGame } from '../../services/lobby.service';
import { StorageKey, useSessionStorage } from '../../hooks/storage.hook';
import { Room } from './room.component';
import { RouteConstant } from '../../constants/route.constants';
import { GameType, GameTypeMap } from '../../generated/lobby_pb';
import { useUrlQuery } from '../../hooks/url-query.hook';
import { GameTypeRoutes } from '../../constants/game-type.constants';

const Container = () => {
  const history = useHistory();
  const { roomCode } = useParams<{ roomCode: string }>();
  const [players, setPlayers] = useState<string[]>([]);
  const [observers, setObservers] = useState<string[]>([]);
  const [playerName] = useSessionStorage(StorageKey.PLAYER_NAME);
  const [playerId, setPlayerId] = useSessionStorage(StorageKey.PLAYER_ID);
  const [ready, setReady] = useState(false);
  const query = useUrlQuery();
  const asObserver = query.get('observer') === 'true';

  useEffect(() => {
    if (playerName === null) {
      history.replace(RouteConstant.HOME);
      return;
    }

    const subscription = joinRoom(roomCode, playerName, playerId || undefined, asObserver).subscribe((room) => {
      setPlayers([...room.players].reverse());
      setObservers([...room.observers].reverse());
      setPlayerId(room.playerId);
      setReady(room.ready);

      if (room.gameId) {
        const [, gameRoute] = Object.entries(GameTypeRoutes).find(
          ([key]) => GameType[key as keyof GameTypeMap] === room.gameType
        )!;

        history.replace(`${gameRoute}/${room.gameId}?${query.toString()}`);
      }
    });

    return () => {
      subscription.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Room
      roomCode={roomCode}
      players={players}
      observers={observers}
      startGame={
        ready
          ? () => {
              if (!playerId) {
                return;
              }

              startGame(roomCode, playerId);
            }
          : undefined
      }
    />
  );
};

export { Container as Room };
