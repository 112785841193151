import { useState } from 'react';

export const useBooleanState = (initialValue: boolean) => {
  const [state, setState] = useState(initialValue);

  const setTrue = () => setState(true);
  const setFalse = () => setState(false);
  const toggle = () => setState(!state);

  return { state, setTrue, setFalse, toggle };
};
