import React from 'react';

import { Settings } from './settings.component';
import { StorageKey, useBooleanLocalStorage } from '../../hooks/storage.hook';

const Container = () => {
  const [classicCardFaces, setClassicCardFaces] = useBooleanLocalStorage(StorageKey.CLASSIC_CARD_FACES);

  return <Settings classicCardFaces={classicCardFaces} setClassicCardFaces={setClassicCardFaces} />;
};

export { Container as Settings };
