import React from 'react';

import styles from './checkbox.module.scss';

type Props = {
  name: string;
  value: boolean;
  label?: string;
  onChange: (value: boolean) => void;
};

export const Checkbox = (props: Props) => {
  return (
    <div className={styles.container}>
      <input
        className={styles.input}
        id={props.name}
        name={props.name}
        type="checkbox"
        checked={props.value}
        onChange={(x) => {
          props.onChange(x.target.checked);
        }}
      />

      <label className={styles.label} htmlFor={props.name}>
        {props.label}
      </label>
    </div>
  );
};
