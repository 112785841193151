import React, { useState } from 'react';
import { useHistory } from 'react-router';

import { StorageKey, useSessionStorage } from '../../hooks/storage.hook';
import { Home } from './home.component';
import { createRoom } from '../../services/lobby.service';
import { RouteConstant } from '../../constants/route.constants';
import { GameType, GameTypeMap } from '../../generated/lobby_pb';

const Container = () => {
  const history = useHistory();
  const [playerName, setPlayerName] = useSessionStorage(StorageKey.PLAYER_NAME);
  const [gameType, setGameType] = useState<GameTypeMap[keyof GameTypeMap]>(GameType.MAHJONG);

  return (
    <Home
      playerName={playerName || ''}
      gameType={gameType}
      updatePlayerName={setPlayerName}
      updateGameType={setGameType}
      createRoom={async () => {
        try {
          const roomCode = await createRoom(gameType);
          history.push(`${RouteConstant.ROOM}/${roomCode}`);
        } catch (e) {
          console.error(e);
        }
      }}
      joinRoom={(roomCode, asObserver) => {
        history.push(`${RouteConstant.ROOM}/${roomCode}?observer=${asObserver}`);
      }}
    />
  );
};

export { Container as Home };
