import React from 'react';
import cx from 'classnames';

import styles from './round-icon-btn.module.scss';

type Props = {
  name: string;
  disabled?: boolean;
  onClick?: () => void;
};

export const RoundIconBtn = ({ name, disabled, onClick }: Props) => {
  return (
    <button className={cx(styles.container, { [styles.disabled]: disabled })} disabled={disabled} onClick={onClick}>
      <svg className={styles.circleContainer} width="100%" height="100%">
        <circle className={styles.circle} cx="50%" cy="50%" r="47%" />
      </svg>

      <i className={`fas fa-${name}`} />
    </button>
  );
};
