import React, { useState } from 'react';
import cx from 'classnames';

import styles from './home.module.scss';
import { GameType, GameTypeMap } from '../../generated/lobby_pb';
import { GameTypeNames } from '../../constants/game-type.constants';
import { RoundIconBtn } from '../round-icon-btn/round-icon-btn.component';
import { TextInput } from '../text-input/text-input.component';
import { Checkbox } from '../checkbox/checkbox.component';
import { Settings } from '../settings/settings.container';

type Props = {
  playerName: string;
  gameType: GameTypeMap[keyof GameTypeMap];
  updatePlayerName: (playerName: string) => void;
  updateGameType: (gameType: GameTypeMap[keyof GameTypeMap]) => void;
  createRoom: () => void;
  joinRoom: (roomCode: string, asObserver: boolean) => void;
};

export const Home = (props: Props) => {
  const [roomCode, setRoomCode] = useState('aaaa');
  const [join, setJoin] = useState(false);
  const [asObserver, setAsOverserver] = useState(false);

  return (
    <div className={styles.container}>
      <Settings />

      <TextInput label="Name" value={props.playerName} onChange={props.updatePlayerName} />

      <div className={styles.card}>
        <div className={styles.tabBar}>
          <button className={cx(styles.tabButton, { [styles.activeTabButton]: !join })} onClick={() => setJoin(false)}>
            Create
          </button>

          <button className={cx(styles.tabButton, { [styles.activeTabButton]: join })} onClick={() => setJoin(true)}>
            Join
          </button>
        </div>

        {join && (
          <div className={styles.tabContainer}>
            <TextInput
              autoFocus={!roomCode.trim()}
              inputClassName={styles.codeTextInput}
              label="Code"
              value={roomCode}
              maxLength={4}
              onChange={setRoomCode}
            />

            <div className={styles.observerCheckbox}>
              <Checkbox name="observer" value={asObserver} label="As observer" onChange={setAsOverserver} />
            </div>
          </div>
        )}

        {!join && (
          <div className={cx(styles.tabContainer, styles.gameTypeContainer)}>
            {Object.entries(GameTypeNames).map(([key, gameTypeName]) => {
              let src;
              const gameType = GameType[key as keyof GameTypeMap];

              switch (gameType) {
                case GameType.MAHJONG:
                  src = require('../../assets/mahjong/ton.svg').default;
                  break;
                case GameType.SEVEN_CLUBS:
                  src = require('../../assets/cards/classic/clubs-seven.svg').default;
                  break;
                case GameType.HOKM:
                  src = require('../../assets/cards/classic/spades-ace.svg').default;
                  break;
              }

              return (
                <div
                  key={key}
                  className={cx(styles.gameTypeButton, {
                    [styles.selected]: props.gameType === gameType,
                  })}
                  onClick={() => props.updateGameType(gameType)}
                  title={gameTypeName}
                >
                  <img src={src} alt={GameTypeNames[key as keyof typeof GameTypeNames]} />
                </div>
              );
            })}
          </div>
        )}
      </div>

      <RoundIconBtn
        name="play"
        disabled={props.playerName.trim() === '' || (roomCode.trim() === '' && join)}
        onClick={async () => {
          if (join) {
            props.joinRoom(roomCode, asObserver);
          } else {
            props.createRoom();
          }
        }}
      />
    </div>
  );
};
