import React, { useRef, useState } from 'react';
import cx from 'classnames';

import styles from './text-input.module.scss';

type Props = {
  label: string;
  value: string;
  maxLength?: number;
  className?: string;
  inputClassName?: string;
  autoFocus?: boolean;
  onChange: (value: string) => void;
};

export const TextInput = (props: Props) => {
  const [isActive, setActive] = useState(props.value !== '');

  const id = useRef(
    Math.random()
      .toString(36)
      .replace(/[^a-z]+/g, '')
      .substring(0, 5)
  );

  return (
    <div className={cx(styles.container, props.className, { [styles.isActive]: isActive })}>
      <div className={styles.innerContainer}>
        <label className={styles.label} htmlFor={id.current}>
          {props.label}
        </label>

        <input
          id={id.current}
          className={cx(styles.input, props.inputClassName)}
          type="text"
          maxLength={props.maxLength}
          value={props.value}
          autoFocus={props.autoFocus}
          onChange={(e) => props.onChange(e.target.value)}
          onFocus={() => setActive(true)}
          onBlur={() => setActive(props.value !== '')}
        />
      </div>
    </div>
  );
};
