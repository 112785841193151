import React from 'react';
import ReactDOM from 'react-dom';

import './index.scss';
import { App } from './components/app/app.component';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

const getVmin = () => {
  const vh = window.innerHeight;
  const vw = window.innerWidth;

  return Math.min(vh, vw) / 100;
};

document.documentElement.style.setProperty('--vmin', `${getVmin()}px`);

const debounce = (f: (...args: any[]) => void, timeout = 300) => {
  let timer: NodeJS.Timeout;

  return (...args: any[]) => {
    clearTimeout(timer);
    timer = setTimeout(() => f(args), timeout);
  };
};

window.addEventListener(
  'resize',
  debounce(() => {
    document.documentElement.style.setProperty('--vmin', `${getVmin()}px`);
  })
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
