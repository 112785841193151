import { useMemo, useState } from 'react';

const useStorage = (key: StorageKey, storage: Storage): [string | null, (v: string) => void] => {
  const [value, setValue] = useState(storage.getItem(key));

  return [
    value,
    (newValue: string) => {
      storage.setItem(key, newValue);
      setValue(newValue);
    },
  ];
};

const useBooleanStorage = (key: StorageKey, storage: Storage): [boolean, (v: boolean) => void] => {
  const [value, setValue] = useStorage(key, storage);

  const rValue = useMemo(() => value === 'true', [value]);

  return [
    rValue,
    (newValue: boolean) => {
      setValue(`${newValue}`);
    },
  ];
};

export const useLocalStorage = (key: StorageKey) => useStorage(key, localStorage);

export const useSessionStorage = (key: StorageKey) => useStorage(key, sessionStorage);

export const useBooleanLocalStorage = (key: StorageKey) => useBooleanStorage(key, localStorage);

export const useBooleanSessionStorage = (key: StorageKey) => useBooleanStorage(key, sessionStorage);

export enum StorageKey {
  PLAYER_NAME = 'playerName',
  PLAYER_ID = 'playerId',
  CLASSIC_CARD_FACES = 'classicCardFaces',
}
